import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e7132c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_AutoForm = _resolveComponent("AutoForm")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "ConfigurationView" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, { span: 4 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              src: _ctx.params?.icon,
              width: "80"
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.params?.title), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, { span: 20 }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            span: 24,
            style: {"font-size":"18px","margin-bottom":"5px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode("介绍")
            ]),
            _: 1
          }),
          _createVNode(_component_a_textarea, {
            autosize: true,
            readonly: "",
            value: _ctx.params?.introduce,
            style: {"width":"100%","resize":"none"}
          }, null, 8, ["value"]),
          _createVNode(_component_a_col, {
            span: 24,
            style: {"font-size":"18px","margin":"5px 0"}
          }, {
            default: _withCtx(() => [
              _createTextVNode("配置")
            ]),
            _: 1
          }),
          _createVNode(_component_AutoForm, { ref: "formRef" }, null, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        span: 24,
        style: {"margin-top":"10px","text-align":"center"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.backPage
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            type: "primary",
            style: {"margin-left":"50px"},
            onClick: _ctx.commit
          }, {
            default: _withCtx(() => [
              _createTextVNode("提交")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}