
import {defineComponent, onMounted, ref} from "vue";
import AutoForm from "./AutoForm.vue"
import {useStore} from "vuex";
import {useRouter, useRoute} from 'vue-router'
import {addDev, addService, updateDev, updateService} from "@/api/api_x2server";
import {message} from 'ant-design-vue';
import {getResultItems} from "@/components/common/autoFormFun";

export default defineComponent({
  components: {AutoForm},
  setup(props, context) {
    const formRef = ref();
    const store = useStore();
    const router = useRouter()
    const route = useRoute()

    const params = ref<any>();
    onMounted(() => {
      params.value = store.getters.getConfigParams;
    })
    const backPage = () => {
      router.go(-1);
    }
    const commit = async () => {
      formRef.value.validate()
          .then(async (res: any) => {
            let Schema = params.value.configSchema.Schema;
            formRef.value.coverItems(Schema.Items)
            let a = Schema.Items.filter((e: any) => (
                e.Key != 'Name' && e.Key != 'Code' && e.Key != 'Des'
            ))
            let Items: any = getResultItems(a)
            let data: any = {
              Type: route.params.configType,
              Name: res.Name,
              Code: res.Code,
              Des: res.Des,
              Config: {
                Key: Schema.Key,
                Items
              }
            }
            let result: any;
            let successText = "添加成功！"
            if (route.params.type == "addDev") {
              result = await addDev(data);
            } else if (route.params.type == "updateDev") {
              successText = "修改成功！"
              data.ID = params.value.id;
              result = await updateDev(data)
            } else if (route.params.type == "addService") {
              result = await addService(data)
            } else if (route.params.type == "updateService") {
              successText = "修改成功！"
              data.ID = params.value.id;
              result = await updateService(data)
            }
            if (result.Result.toUpperCase() == "OK") {
              message.success(successText)
              if (route.params.type == "addDev") {
                router.push("/device")
              } else if (route.params.type == "addService") {
                router.push("/service")
              } else {
                backPage()
              }
            }
          })
    }
    return {
      route,
      formRef,
      params,
      backPage,
      commit,
    }
  }
})
